import { render, staticRenderFns } from "./chef-card.vue?vue&type=template&id=eb96b924"
import script from "./chef-card.vue?vue&type=script&lang=ts"
export * from "./chef-card.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChefImageSlider: require('/vercel/path0/components/chef/image-slider.vue').default,UiIconChefHat: require('/vercel/path0/components/ui/icon/chef-hat.vue').default,UiRating: require('/vercel/path0/components/ui/rating.vue').default})
