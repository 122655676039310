import { render, staticRenderFns } from "./accordion.vue?vue&type=template&id=69432717"
import script from "./accordion.vue?vue&type=script&lang=ts"
export * from "./accordion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconChevronDown: require('/vercel/path0/components/ui/icon/chevron-down.vue').default})
