
import Vue from 'vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ChefCard from '~/components/index/chef-highlight/chef-card.vue'

export interface ChefCardInterface {
  id: number;
  title: string;
  subtitle: string;
  slug: string;
  images: { title: string, url: string }[];
  min_guests: number;
  max_guests: number;
  price_from: string;
  review_amount: number;
  review_score: number;
  location: string;
  super_chef: boolean;
}

export default Vue.extend({
  name: 'chefHighlight',
  components: {
    ChefCard,
    Swiper,
    SwiperSlide
  },
  mounted() {
    setTimeout(() => {
      // @ts-ignore
      this.swiperInstance = this.$refs.swiper?.$swiper
    }, 350);
  },
  async fetch() {
    const data = await this.$axios.$get('/v1/listing/latest?limit=6')
    this.chefs = data.data;
  },
  data (): { loading: boolean, swiperInstance: undefined, chefs: ChefCardInterface[], swiperOptions: object } {
    return {
      loading: true,
      swiperInstance: undefined,
      chefs: [],
      swiperOptions: {
        breakpoints: {
          1: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          700: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1000: {
            slidesPerView: 3,
            spaceBetween: 20
          }
        }
      }
    }
  }
})
