
import Vue from 'vue';
import UspCard, {UspCardInterface} from '~/components/index/usp/usp-card.vue'

export default Vue.extend({
  name: 'usp',
  components: {UspCard},
  data(): { cards: UspCardInterface[] } {
    return {
      cards: [
        {
          image: '/icon/chef.svg',
          title: 'Geverifieerde cateraars',
          description: 'Zorgvuldige selectie op kwaliteit, ervaring & professionaliteit'
        },
        {
          image: '/icon/gloves.svg',
          title: 'Direct online boeken',
          description: 'Razendsnelle communicatie zodat je alles in een handomdraai kan regelen.'
        },
        {
          image: '/icon/check-gradient.svg',
          title: 'Nergens goedkoper',
          description: 'Duidelijke prijzen die voor hetzelfde arrangement nergens beter te vinden zijn.'
        },
        {
          image: '/icon/shield.svg',
          title: 'Gratis annuleren',
          description: 'Tot 7 dagen voor je diner kan je er kosteloos van afzien.'
        }
      ]
    }
  }
});
