
import Vue from 'vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import CategoryCard, {categoryCardInterface} from '~/components/index/category/category-card.vue'

export default Vue.extend({
  name: 'category',
  components: {
    CategoryCard,
    Swiper,
    SwiperSlide
  },
  mounted() {
    setTimeout(() => {
      // @ts-ignore
      this.swiperInstance = this.$refs.swiper?.$swiper
    }, 350);
  },
  data(): { categories: categoryCardInterface[], swiperInstance: undefined, swiperOptions: object } {
    return {
      categories: [],
      swiperInstance: undefined,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          576: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 30
          },
          1440: {
            slidesPerView: 5,
            spaceBetween: 40
          }
        }
      }
    }
  },
  async fetch() {
    const data = await this.$axios.$get('/v1/category/1')
    this.categories = data.data.features
  }
})
