
import Vue, { VueConstructor } from 'vue'
import ResponsiveMixin from '~/mixins/responsive.mixin'

export default (Vue as VueConstructor<Vue & InstanceType<typeof ResponsiveMixin>>).extend({
  name: 'swiperButtons',
  mixins: [ResponsiveMixin],
  data () {
    return {
      canSlideNext: false,
      canSlidePrev: false,
    }
  },
  props: {
    swiper: {
      type: Object,
      default () {
        return undefined
      }
    },
  },
  watch: {
    swiper () {
      if (this.swiper !== undefined) {
        setTimeout(() => {
          this.updateSwiperSettings();
        }, 10)
      }
    },
    clientWidth () {
      this.updateSwiperSettings()
    }
  },
  methods: {
    updateSwiperSettings () {
      if (this.swiper) {
        this.canSlidePrev = this.swiper.activeIndex !== 0
        this.canSlideNext = !this.swiper.isEnd
      }
    },
    prev () {
      // @ts-ignore
      this.swiper.slidePrev()
      this.updateSwiperSettings()
    },
    next () {
      // @ts-ignore
      this.swiper.slideNext()
      this.updateSwiperSettings()
    }
  },
})
