
import Vue from 'vue'

export default Vue.extend({
  name: 'navbar',
  props: {
    mobileMenuOpen: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      menu: {
        center: [
          {
            text: 'Alle arrangementen',
            link: '/bekijk-alle-chefs/',
            nuxtLink: false, // should implement close mobileMenuOpen onClick and fix bug when navigating from homepage directly to this CHEFSTBL-374
          },
          {
            text: 'Hoe werkt het?',
            link: '/hoe-verloopt-een-diner/',
            nuxtLink: false,
          },
          {
            text: 'Help mij kiezen',
            link: '/advies/',
            nuxtLink: false,
          },
          {
            text: 'Klantverhalen',
            link: '/klantverhalen-en-reviews/',
            nuxtLink: false,
          },
          {
            text: 'Zakelijk',
            link: '/prive-chef-voor-zakelijk-diner/',
            nuxtLink: false,
          },
          {
            text: 'Giftcard',
            link: '/giftcard/',
            nuxtLink: false,
          },
          {
            text: 'Over ons',
            link: '/over-ons/',
            nuxtLink: false,
          }
        ]
      }
    }
  },
  methods: {
    openMobileMenu(state: boolean) {
      this.$emit('mobileMenuOpen', state)
    },
  }
})
