
import Vue, { PropOptions } from 'vue'

export interface UspCardInterface {
  image: string;
  title: string;
  description: string;
}

export default Vue.extend({
  name: 'uspCard',
  props: {
    usp: {
      type: Object,
      required: true
    } as PropOptions<UspCardInterface>
  }
})
