import { render, staticRenderFns } from "./swiper-buttons.vue?vue&type=template&id=143eaaf0"
import script from "./swiper-buttons.vue?vue&type=script&lang=ts"
export * from "./swiper-buttons.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconChevronLeft: require('/vercel/path0/components/ui/icon/chevron-left.vue').default,UiIconChevronRight: require('/vercel/path0/components/ui/icon/chevron-right.vue').default})
