import { render, staticRenderFns } from "./chef-highlight.vue?vue&type=template&id=409736d6"
import script from "./chef-highlight.vue?vue&type=script&lang=ts"
export * from "./chef-highlight.vue?vue&type=script&lang=ts"
import style0 from "./chef-highlight.vue?vue&type=style&index=0&id=409736d6&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSwiperButtons: require('/vercel/path0/components/ui/swiper-buttons.vue').default,UiContainer: require('/vercel/path0/components/ui/container.vue').default})
