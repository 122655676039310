
import Vue, {PropOptions} from 'vue'
import {ChefCardInterface} from '~/components/index/chef-highlight.vue'
import {mapGetters} from 'vuex'
import moment from 'moment'

export default Vue.extend({
  name: 'chefCard',
  props: {
    hidePricing: {
      type: Boolean,
      default: false,
    },
    notClickable: {
      type: Boolean,
      default: false,
    },
    chef: {
      type: Object,
      required: true
    } as PropOptions<ChefCardInterface>,
    subtitle: {
      type: String,
      default: "",
    }
  },
  computed: {
    ...mapGetters({
      filters: 'filter/filter'
    }),
    computedSubtitle() {
      if (this.subtitle) {
        return this.subtitle
      }

      return this.chef.subtitle
    },
    chefDetailUrl() {
      const query: { [key: string]: string } = {};

      if (this.filters.person) {
        query[`number_of_guests`] = this.filters.person;
      }

      if (this.filters.address && this.filters.address.fullTitle) {
        query['location_search'] = this.filters.address.fullTitle;
      }

      if (this.filters.calendar) {
        query['date'] = moment(this.filters.calendar).format('DD-MM-YYYY');
      }

      const queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');

      return `/chef/${this.chef.slug}/${queryString.length > 0 ? ('?' + queryString) : ''}`
    }
  }
})
