import { render, staticRenderFns } from "./usp.vue?vue&type=template&id=bf4881f6"
import script from "./usp.vue?vue&type=script&lang=ts"
export * from "./usp.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiContainer: require('/vercel/path0/components/ui/container.vue').default})
