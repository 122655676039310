import { render, staticRenderFns } from "./category.vue?vue&type=template&id=4566e19a"
import script from "./category.vue?vue&type=script&lang=ts"
export * from "./category.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSwiperButtons: require('/vercel/path0/components/ui/swiper-buttons.vue').default,UiContainer: require('/vercel/path0/components/ui/container.vue').default,UiContainerXl: require('/vercel/path0/components/ui/container-xl.vue').default})
