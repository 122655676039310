
import Vue from 'vue';
import OccasionCard, {OccasionCardInterface} from '~/components/index/occasions/occasion-card.vue'

export default Vue.extend({
  components: {OccasionCard},
  data(): { occasions: OccasionCardInterface[] } {
    return {
      occasions: [
        {
          title: 'Bedrijfsevenement',
          image: 'https://cdn.chefstbl.com/occasion/bedrijfsevenement.jpg',
          url: '/prive-chef-voor-zakelijk-diner/',
        }, {
          title: '21 dinner',
          image: 'https://cdn.chefstbl.com/occasion/21-dinner.jpg',
          url: '/21-diner-kok-aan-huis/',
        }, {
          title: 'Verjaardag',
          image: 'https://cdn.chefstbl.com/occasion/verjaardag.jpg',
          url: '/catering-verjaardag/',
        }, {
          title: 'Op vakantie',
          image: 'https://cdn.chefstbl.com/occasion/vakantie.jpg',
          url: '/thuiskok-vakantiehuis/',
        }, {
          title: 'Huwelijk',
          image: 'https://cdn.chefstbl.com/occasion/bruiloft.jpg',
          url: '/catering-bruiloft/',
        }
      ]
    }
  }
});
