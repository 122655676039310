
import Vue from 'vue'

export default Vue.extend({
  name: 'rating',
  props: {
    rating: {
      type: Number,
      required: true,
    }
  },
  data (): { stars: string[] } {
    return {
      stars: []
    }
  },
  watch: {
    rating: {
      handler (): void {
        this.computeStars();
      },
      immediate: true,
    },
  },
  methods: {
    computeStars() {
      // Reset first
      this.stars = [];

      const fiveStarRating = this.rating / 2
      const wholeStars = Math.floor(fiveStarRating)

      for (let i = 0; i < wholeStars; i++) {
        this.stars.push('star');
      }

      const remainder = fiveStarRating % 1

      if (remainder < 0.25 && remainder > 0.01) {
        this.stars.push('star-empty')
      } else if ( remainder >= 0.25 && remainder <= 0.75) {
        this.stars.push('star-half');
      } else if (remainder > 0.75) {
        this.stars.push('star')
      }

      if (this.stars.length < 5) {
        for (let i = this.stars.length; i < 5; i++) {
          this.stars.push('star-empty');
        }
      }
    }
  }
})
