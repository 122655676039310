import { render, staticRenderFns } from "./navbar.vue?vue&type=template&id=70bffa98"
import script from "./navbar.vue?vue&type=script&lang=ts"
export * from "./navbar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiIconLogo: require('/vercel/path0/components/ui/icon/logo.vue').default,UiLink: require('/vercel/path0/components/ui/link.vue').default,UiIconMenu: require('/vercel/path0/components/ui/icon/menu.vue').default,UiIconClose: require('/vercel/path0/components/ui/icon/close.vue').default})
