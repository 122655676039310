
import Vue from 'vue';
import FilterBar from '~/components/filter/filter-bar.vue'

export default Vue.extend({
  name: 'Hero',
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  components: {FilterBar},
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  computed: {
    titleA(): String {
      if (this.title) {
        return this.title;
      }

      return this.$t('index.hero.find-chef').toString()
    }
  }
})
