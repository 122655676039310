
import Vue from 'vue';

export default Vue.extend({
  name: 'faq',
  data() {
    return {
      faqs: [
        {
          title: 'In welke steden komen jullie cateraars?',
          content: 'Onze cateraars werken in heel Nederland en België. Als je op de site je locatie, datum en aantal personen aangeeft kan je precies zien welke cateraars er beschikbaar zijn om bij jou te komen koken.'
        },
        {
          title: 'Is een reservering vrijblijvend?',
          content: 'Op het moment dat je reserveert, zit je nog helemaal nergens aan vast. We gaan eerst dubbelchecken of de cateraar beschikbaar is op de betreffende datum. Je krijgt dan een bericht van ons dat je reservering klaar staat om te bevestigen.'
        },
        {
          title: 'Wat kost het om een cateraar te laten komen?',
          content: 'Op de pagina van de cateraar kan je precies zien wat de prijzen zijn. Zodra je je locatie en het aantal personen invult zie je precies wat een diner voor jouw gezelschap gaat kosten.'
        },
        {
          title: 'Waaraan moet mijn keuken voldoen?',
          content: 'Bij voorkeur een schone keuken met 4 kookpitten, voldoende werkruimte, plek in de koelkast en vriezer, een oven en een vaatwasser. Heb je dat niet? Geef het even aan in je reservering en dan houdt de cateraar hier rekening mee.'
        },
        {
          title: 'Kan ik mijn reservering nog annuleren?',
          content: 'We zullen altijd in goed overleg met jou en de cateraar de beste oplossing zoeken. Mocht dat niet lukken, dan gelden de volgende annuleringsvoorwaarden. Tot 7 dagen voor je diner kan je kosteloos annuleren. Stel ons op de hoogte en wij maken het volledige bedrag over naar je rekening. Als je binnen 7 dagen annuleert krijg je 50% van het totaalbedrag terug. Bij annuleringen vanaf 1 dag voor het diner kun je geen geld meer terugkrijgen. Het herroepingsrecht van consument is uitgesloten.'
        },
        {
          title: 'Kan de cateraar rekening houden met allergieën',
          content: 'Wij werken uitsluitend met echte professionals die met jouw allergie rekening kunnen houden. Geef bij de reservering duidelijk aan om welke allergieën het gaat.',
        }
      ]
    }
  }
})
