
import Vue, {PropOptions} from 'vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default Vue.extend({
  name: 'ChefImageSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
    listingUrl: {
      type: String,
      default: '',
    },
    images: {
      type: Array,
      default() {
        return []
      }
    } as PropOptions<{ title: string, url: string }[]>,
    rating: {
      type: Number,
      default: 0
    } as PropOptions<number>,
    selectedImage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    lightBoxImages(): string[] {
      return this.images.map(image => {
        return image.url
      })
    },
  },
  data() {
    return {
      swiperClass: {
        'max-h-64': this.compact,
        'max-h-96': !this.compact,
      },
      swiperSlideClasses: {
        'h-64': this.compact,
        'h-72': !this.compact,
        'md:h-96': !this.compact,
      },
      swiperButtonClasses: {
        'top-[104px]': this.compact,
        'top-[120px]': !this.compact,
        'md:top-[172px]': !this.compact,
      },
      lightBoxIndex: null,
      swiperInstance: undefined,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          },
        }
      }
    }
  },
  mounted() {
    // @ts-ignore
    this.swiperInstance = this.$refs.swiper?.$swiper
  },
  watch: {
    selectedImage() {
      if (this.selectedImage > 0 && this.swiperInstance) {
        // @ts-ignore
        this.swiperInstance.slideTo(this.selectedImage, 100)
      }
    }
  },
})
