
import Vue, {PropOptions} from 'vue'

interface ImageInterface {
  title: string;
  url: string;
}

export interface categoryCardInterface {
  id: number;
  name: string;
  slug: string;
  description?: string;
  listing_count: number;
  image?: ImageInterface
}

export default Vue.extend({
  name: 'category-card',
  props: {
    category: {
      type: Object,
      required: true
    } as PropOptions<categoryCardInterface>
  },
  computed: {
    url(): string {
      const resolve = this.$router.resolve({
        name: `search_${this.category.slug}___nl`,
      });

      if (resolve.resolved.matched.length > 0) {
        return resolve.href + '/';
      }

      return `/type-chef/${this.category.slug}/`
    },
    imageUrl(): string {
      return this.category.image ? this.category.image.url : '/assets/img/category.webp'
    }
  }
})
