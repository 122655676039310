
import Vue from 'vue'

export interface statsInterface {
  review_amount: number;
  review_score: number;
  chef_amount: number;
  listing_amount: number;
}

export default Vue.extend({
  name: 'references',
  head() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org/",
            "@type": "AggregateRating",
            "itemReviewed": {
              "@type": "Organization",
              "name": "CHEFSTBL",
            },
            "ratingValue": String(this.$data.stats.review_score),
            "bestRating": "10",
            "reviewCount": String(this.$data.stats.review_amount)
          }
        }
      ]
    }
  },
  data(): { images: string[], stats: statsInterface } {
    return {
      stats: {
        review_amount: 0,
        review_score: 0,
        chef_amount: 0,
        listing_amount: 0
      },
      images: [
        'linda',
        'bnr-nieuwsradio',
        'hart-van-nederland',
        'nu',
        'de-ondernemer',
        'ad',
        'emerce',
        'sublime',
        'brabants-dagblad'
      ]
    }
  },
  async fetch() {
    // @ts-ignore
    this.stats = await this.$axios.$get('/v1/stats').then(data => data.data)

    if (this.stats) {

    }
  }
})
