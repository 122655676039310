
import Vue from 'vue';
import Hero from '~/components/index/hero.vue'
import References from '~/components/index/references.vue'
import DinnerKind from '~/components/index/category.vue'
import Occasions from '~/components/index/occasions.vue'
import Description from '~/components/index/description.vue'
import Usp from '~/components/index/usp.vue'
import ChefHighlight from '~/components/index/chef-highlight.vue'
import Faq from '~/components/index/faq.vue'
import 'swiper/css/swiper.css'

export default Vue.extend({
  name: 'IndexPage',
  components: {Description, Faq, ChefHighlight, Usp, Occasions, DinnerKind, References, Hero},
  head: {
    bodyAttrs: {
      class: 'bg-white text-offBlack'
    }
  }
})
