
import Vue from 'vue'

export default Vue.extend({
  name: 'accordion',
  data () {
    return {
      isOpen: false,
    }
  }
})
