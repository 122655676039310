
import Vue, {VueConstructor} from 'vue'
import ResponsiveMixin from '~/mixins/responsive.mixin'
import FilterStateMixin from '~/mixins/filter-state.mixin'
import {updateQueryParams} from '~/util/query-parser'
import {AddressInterface} from '~/components/filter/address-search.vue'

export default (Vue as VueConstructor<Vue & InstanceType<typeof ResponsiveMixin> & InstanceType<typeof FilterStateMixin>>).extend({
  name: 'FilterBar',
  mixins: [ResponsiveMixin, FilterStateMixin],
  data(): {
    barVisible: boolean;
  } {
    return {
      barVisible: true
    }
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.detectScrollOutOfView)
      this.detectScrollOutOfView()
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.detectScrollOutOfView)
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    openMobileMenu() {
      this.$emit('mobileMenuOpen', true)
    },
    detectScrollOutOfView() {
      const position = (this.$refs.searchBar as HTMLElement).getBoundingClientRect()
      this.barVisible = position.top < window.innerHeight && position.bottom >= 0
    },
    findChef() {
      if (this.addressState === undefined && this.calendarState === undefined && this.personState === 0) {
        // @ts-ignore
        this.isAddressOpen = true
      } else {
        this.$router.push({
          path: '/bekijk-alle-chefs/',
          query: updateQueryParams({
            dynamicFilters: {},
            address: this.addressState as AddressInterface,
            calendar: this.calendarState as Date,
            person: this.personState as number
          })
        });
      }
    }
  }
})
