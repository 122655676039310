
import {truncate} from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      required: true
    },
    truncateLength: {
      type: Number,
      default: 175,
      required: false
    }
  },
  data() {
    return {
      readMoreTextOpen: false
    }
  },
  computed: {
    truncateText() {
      return truncate(this.text, {
        length: this.$data.readMoreTextOpen ? this.text.length : this.truncateLength,
        omission: '...',
      });
    }
  }
})
