
import Vue, {PropOptions} from 'vue';

export interface OccasionCardInterface {
  image: string;
  title: string;
  url: string;
}

export default Vue.extend({
  name: 'occasionCard',
  props: {
    occasion: {
      type: Object,
      required: true
    } as PropOptions<OccasionCardInterface>
  }
})
